<template>
  <div class="my" :class="{ isMobile }">
    <el-row gutter="2">
      <el-col v-if="!isMobile" class="left" :span="3" :offset="3">
        <el-card>
          <el-menu
            router
            active-text-color="#3786fa"
            text-color="#999"
            :ellipsis="false"
            style="--el-menu-border-color: #2c3e50; border: none"
            @select="selectSubMenu"
            default-active="/myInfo"
          >
            <el-menu-item index="/myInfo">我的信息</el-menu-item>
            <el-menu-item index="/recommended">我的推荐</el-menu-item>
            <el-menu-item index="/bindWx" @click="aaa"
              >绑定微信公众号</el-menu-item
            >
          </el-menu>
        </el-card>
      </el-col>
      <el-col class="right" :span="13" :xs="22">
        <div class="icon" v-if="isMobile" @click="isShowMenu = true">
          <el-icon :size="44"> <expand /></el-icon>
        </div>
        <el-card> <router-view></router-view> </el-card>
      </el-col>
    </el-row>
    <el-drawer
      lock-scroll
      append-to-body
      :with-header="false"
      size="300"
      custom-class="phone-drawer"
      v-model="isShowMenu"
      direction="btt"
    >
      <el-menu
        router
        active-text-color="#3786fa"
        text-color="#999"
        :ellipsis="false"
        style="--el-menu-border-color: #2c3e50; border: none"
        @select="isShowMenu = false"
        default-active="/myInfo"
      >
        <el-menu-item index="/myInfo">我的信息</el-menu-item>
        <el-menu-item index="/recommended">我的推荐</el-menu-item>
        <el-menu-item index="/bindWx">绑定微信公众号</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
import { Expand } from '@element-plus/icons-vue'

export default {
  components: {
    Expand
  },

  data() {
    return {
      isShowMenu: false
    }
  },

  mounted() {},

  methods: {
    aaa() {}
  }
}
</script>

<style lang="scss" scoped>
.my {
  padding-top: 20px;
  .left,
  .right {
    padding: 10px 20px;
    border-radius: 10px;
  }

  .left {
    .el-card {
    }
    margin-right: 20px;
    height: 100%;
  }

  .right {
  }
}

.isMobile {
  .right {
    position: relative;

    margin-bottom: 20px;
    .icon {
      position: absolute;
      left: 0;
      top: 0;

      text-align: left;
      font-size: 20px;
      color: #2483ff;
      padding: 10px;
    }
  }
}
</style>
